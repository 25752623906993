@@ -0,0 +1,30 @@
<template>
  <article class="modal_info">
    <section class="modal_info__modal" style="height:fit-content;border-radius:5px">
      <div class="modal_info__modal__info">
        <h5>{{ description }}</h5>
      </div>
      <div class="modal_info__modal__buttons">
        <button class="modal_info__modal__buttons--confirm" style="margin-top:0px;width:66%!important;border-radius:5px"
          @click="closeModal()">
          Ok
        </button>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  computed: {
    description() {
      return this.getAlertMessage();
    },
  },
  methods: {
    closeModal() {
      this.updateAlertMessage("");
      this.updateIsInAlert(false);
    },
  },
};
</script>

<template>
	<article class="monitoring__main_section">
		<div
			class="monitoring__drawer"
			v-if="getSelectedCompany() !== null">
			<v-list-item class="monitoring__container--header">
				<v-list-item-content>
					<v-list-item-title
						class="text-h4 clients_head"
						:title="getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).name"
						v-if="getSelectedCompany() !== null">
						<component v-bind:is="getCurrentSection().icon"></component>
						<p>{{ getCurrentSection().title }}</p>
						<div
							class="clients_head__arrow"
							:class="{'clients_head__arrow--active': getShowCompanySections()}"
							@click="updateShowCompanySections(!getShowCompanySections())">
							<drop-down-arrow></drop-down-arrow>
						</div>
					</v-list-item-title>
				</v-list-item-content>
				<company-sections v-if="getShowCompanySections()"></company-sections>
			</v-list-item>
			<v-divider></v-divider>
			<v-list class="monitoring__locations_container">
				<!-- <v-list-item
					v-for="(location, i) in new_event_locations"
					:key="i"
					@click="selectLocation(location.id)"
					class="monitoring__container--item new_event_row"
					:class="[location.pending_events > 0 ? 'alert' : 'normal',
									location.id === selected_location && location.pending_events > 0 ? 'selected_alert': location.id === selected_location && location.pending_events === 0 ? 'selected' :'']">
					<v-list-item-content>
						<v-list-item-title :title="location.name">
							<v-row
								no-gutters
								class="monitoring__container--item_content">
								<v-col
									class="monitoring__container--item_title"
									:class="{'new_event_color': findLocationInEvents(location.id)}">
									{{ location.name }}
								</v-col>
								<v-col cols="auto">
									<arrow-circle-right-icon
										width="30"
										height="30"
										v-if="location.id === selected_location"
										:color="location.pending_events > 0 ? '#606e93' : ''"></arrow-circle-right-icon>
									<circle-icon
										width="30"
										height="30"
										v-else-if="location.pending_events > 0 && !findLocationInEvents(location.id)"
										class="new_event"></circle-icon>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				<v-list-item
					v-for="(location, i) in monitored_locations"
					:key="i"
					@click="selectLocation(location.id)"
					class="monitoring__container--item"
					:class="[location.pending_events > 0 ? 'alert' : 'normal',
									location.id === selected_location && location.pending_events > 0 ? 'selected_alert': location.id === selected_location && location.pending_events === 0 ? 'selected' :'',
									findLocationInEvents(location.id) ? 'new_event_row' : '']">
					<v-list-item-content>
						<v-list-item-title :title="location.name">
							<v-row
								no-gutters
								class="monitoring__container--item_content">
								<v-col
									class="monitoring__container--item_title"
									:class="{'new_event_color': findLocationInEvents(location.id)}">
									{{ location.name }}
								</v-col>
								<v-col cols="auto">
									<arrow-circle-right-icon
										width="30"
										height="30"
										v-if="location.id === selected_location"
										:color="location.pending_events > 0 ? '#606e93' : ''"></arrow-circle-right-icon>
									<circle-icon
										width="30"
										height="30"
										v-else-if="location.pending_events > 0 && !findLocationInEvents(location.id)"
										:class="[findLocationInEvents(location.id) ? 'new_event' : '']"></circle-icon>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>
		<div
			class="monitoring__event_groups"
			v-if="selected_location != null && location_events_type === 'perimeter'">
			<v-tabs
				v-model="tabs"
				color="black"
				background-color="transparent"
				class="monitoring__tabs perimeter"
				@change="search_date = ''">
				<v-tab
					href="#tab-1"
					class="pending"
					style="width: 49%;">Pendientes</v-tab>
				<v-tab
					href="#tab-2"
					class="reviewed"
					style="width: 49%;">
					<circle-check-icon
						width="28" height="28"
						style="margin-right: 5px;"></circle-check-icon>Revisados
				</v-tab>
			</v-tabs>
			<div class="monitoring__event_groups_content">
				<v-tabs-items v-model="tabs">
					<v-tab-item value="tab-1">
						<section class="monitoring__event_groups_content--date_container">
							<div class="">
								<v-btn
									class="resize" :class="today_active ? 'selected' : ''"
									style="margin-right: 10px"
									@click="selectDate(true)">
									Hoy
								</v-btn>
								<v-menu
									ref="menu"
									:close-on-content-click="true"
									:return-value.sync="search_date"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											style="min-width: 40px; padding: 0;"
											v-bind="attrs"
											v-on="on">
											<span class="material-icons">calendar_month</span>
										</v-btn>
									</template>
									<v-date-picker
										v-model="search_date"
										no-title
										scrollable
										@change="selectDate(false)"
									>
									</v-date-picker>
								</v-menu>
							</div>
							<v-btn
								class="resize" :class="pending_active? 'selected' : ''"
								@click="selectDate(false, true)">
								Pendientes
							</v-btn>
						</section>

						<div
							class="monitoring__event_groups_content--event_list"
							ref="eventList"
							@scroll="loadPendingEvents">
							<div
								class="monitoring__no_events"
								v-if="location_pending_events.length == 0">
								No se han reportado eventos pendientes en la fecha seleccionada
							</div>
							<div
								v-for="(event_group, i) in Object.keys(pending_event_groups).reverse()"
								:key="i"
								class="monitoring_card">
								<div
									class="monitoring__event_groups_content--event_group clickable"
									v-if="pending_event_groups[event_group].filter(ev => !ev.hide).length == pending_event_groups[event_group].filter(ev => ev.event_state == 'VERIFIED').length"
									@click="selectEvent(pending_event_groups[event_group].slice(-1)[0], event_group)"
									:style="selected_event_group !== null && selected_event_group == event_group && show_event_procedure_form ? 'background-color: #dae3f4' : ''">
									<!-- <v-icon :color="selected_event_group !== null && selected_event_group == event_group && show_event_procedure_form ? 'primary' : ''">
										mdi-arrow-up-bold
									</v-icon> -->
									<b>{{ event_group | moment("h:mm:ss a") }}</b>
									<b>{{ event_group | moment("DD MMM YYYY") }}</b>
									<span class="material-icons next">navigate_next</span>
								</div>
								<div
									v-else
									class="monitoring__event_groups_content--event_group"
									:style="selected_event_group !== null && selected_event_group == event_group && show_event_procedure_form ? 'background-color: #dae3f4' : ''">
									<!-- <v-icon :color="selected_event_group !== null && selected_event_group == event_group && show_event_procedure_form ? 'primary' : ''">
										mdi-arrow-up-bold
									</v-icon> -->
									<b>{{ event_group | moment("h:mm:ss a") }}</b>
									<b>{{ event_group | moment("DD MMM YYYY") }}</b>
								</div>
								<ul
									v-if="pending_event_groups[event_group].length > 0"
									style="padding: 0">
									<li
										v-for="(pend_event, index) in pending_event_groups[event_group]"
										v-show="!pend_event.hide"
										:key="index"
										class="monitoring__event_groups_content--event"
										:style="selected_event !== null && selected_event.id == pend_event.id && !show_event_procedure_form ? 'background-color: #dae3f4' : ''"
										@click="selectEvent(pend_event); selected_event_group = event_group;">
										<!-- <span
											:style="pend_event.event_code == '131588' ? 'background-color: #ff786f'
											: pend_event.event_code == 'FUSE-ALERT-COM' ? 'background-color: #62a5ff'
											: 'background-color: #ffbe38'">
										</span> -->
										<!-- <p style="margin-right: 1.5rem">{{pend_event.event_time | moment("DD MMM YYYY")}}</p> -->
										<p>{{pend_event.event_time | moment("h:mm:ss a")}}</p>
										<div class="monitoring_card__checkbox"
											@click="verifyEvent(pend_event, event_group)">
											<checkbox-done
												v-if="pend_event.event_state == 'VERIFIED'"></checkbox-done>
											<checkbox-empty
												v-else></checkbox-empty>
										</div>
										<!-- <span class="material-icons" @click="verifyEvent(pend_event, event_group)">
											{{ pend_event.event_state == 'VERIFIED' ? 'done' : '' }}
										</span> -->
									</li>
								</ul>

							</div>
						</div>
						<div
							class="monitoring__loading_events"
							v-show="loading_events">
							<span class="material-icons">timer</span> Cargando...
						</div>
					</v-tab-item>
					<v-tab-item value="tab-2">
						<div
							class="monitoring__event_groups_content--event_list"
							ref="eventList"
							@scroll="loadReviewedEvents">
							<div
								class="monitoring__no_events"
								v-if="location_reviewed_events.length == 0">
								No se han encontrado eventos revisados en la fecha seleccionada
							</div>
							<div
								v-for="(event_group, i) in Object.keys(reviewed_event_groups).reverse()"
								:key="i"
								class="monitoring_card">
								<div
									class="monitoring__event_groups_content--event_group clickable"
									:style="selected_event_group !== null && selected_event_group == event_group ? 'background-color: #dae3f4' : ''"
									@click="selectEvent(reviewed_event_groups[event_group].slice(-1)[0], event_group)">
									<!-- <v-icon :color="selected_event_group !== null && selected_event_group == event_group ? 'primary' : ''">
										mdi-arrow-up-bold
									</v-icon> -->
									<b>{{ event_group | moment("h:mm:ss a") }}</b>
									<b>{{ event_group | moment("DD MMM YYYY") }}</b>
									<span class="material-icons">navigate_next</span>
								</div>
								<ul
									v-if="reviewed_event_groups[event_group].length > 0"
									style="padding: 0">
									<li
										v-for="(reviewed_event, index) in reviewed_event_groups[event_group]"
										v-show="!reviewed_event.hide"
										:key="index"
										class="monitoring__event_groups_content--event"
										:style="selected_event !== null && selected_event.id == reviewed_event.id && !selected_event_group ? 'background-color: #dae3f4' : ''"
										@click="selectEvent(reviewed_event)">
										<!-- <span
											:style="reviewed_event.event_code == '131588' ? 'background-color: #ff786f'
											: reviewed_event.event_code == 'FUSE-ALERT-COM' ? 'background-color: #62a5ff'
											: 'background-color: #ffbe38'">
										</span> -->
										<!-- <p style="margin-right: 1.5rem">{{reviewed_event.event_time | moment("DD MMM YYYY")}}</p> -->
										<p>{{reviewed_event.event_time | moment("h:mm:ss a")}}</p>
										<div class="monitoring_card__checkbox"
											@click="verifyEvent(pend_event, event_group)">
											<checkbox-done></checkbox-done>
										</div>
									</li>
								</ul>

							</div>
						</div>
						<div
							class="monitoring__loading_events"
							v-show="loading_events">
							<span class="material-icons">timer</span> Cargando...
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</div>
		<div
			v-else-if="selected_location != null && location_events_type === 'facial'"
			class="monitoring__facial_events_container">
			<v-tabs
				v-model="tabs"
				color="black"
				background-color="transparent"
				class="monitoring__tabs">
				<v-tab
					href="#tab-1"
					class="pending">Pendientes</v-tab>
				<v-tab
					href="#tab-2"
					class="reviewed">
					<circle-check-icon
						width="28"
						height="28"></circle-check-icon>Revisados
					</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabs">
				<v-tab-item value="tab-1">
					<div
						class="monitoring__no_events"
						v-if="location_pending_events.length == 0">
						Aún no se han reportado eventos de dispositivos
					</div>
					<div
						class="monitoring__facial_events_section"
						v-else-if="location_pending_events.length > 0 && facial_events_ready"
						style="padding-bottom: 0;">
						<div class="monitoring__preview_facial_events">
							<div
								class="monitoring__preview_facial_events--photo"
								style="border: 3px solid #ff2a2a; width: 100%;">
								<div
									class="monitoring__preview_facial_events--photo_title"
									style="background-color: #ff2a2a;">
									NO AUTORIZADO
								</div>
								<img :src="pending_event_selected.photo" alt="event_image">
							</div>
							<!-- <v-card class="monitoring__preview_facial_events--info">
								<v-card-title
									class="monitoring__preview_facial_events--info_title"
									:title="selected_location.name">
									{{ selected_location.name }}
								</v-card-title>
								<div class="monitoring__preview_facial_events--info_item">
									Locación:
									<b :title="selected_location.name">{{ selected_location.name }}</b>
								</div>
								<div class="monitoring__preview_facial_events--info_item">
									Fecha:
									<b>{{ pending_event_selected.event_time | moment("DD MMM YYYY") }}</b>
								</div>
								<div class="monitoring__preview_facial_events--info_item">
									Hora:
									<b>{{ pending_event_selected.event_time | moment("h:mm:ss a") }}</b>
								</div>
								<div class="monitoring__preview_facial_events--info_btn">
									<v-btn
										color="primary"
										@click="show_facial_events_procedure_modal = true">Procedimiento</v-btn>
								</div>
							</v-card> -->
						</div>
						<div
							class="monitoring__pending_facial_events"
							@scroll="loadPendingEvents">
							<div
								class="monitoring__loading_events"
								v-show="loading_events">
								<span class="material-icons">timer</span> Cargando...
							</div>
							<div class="monitoring__pending_facial_events--container">
								<v-card
									class="monitoring__pending_facial_events--card"
									v-for="(facial_event, index) in location_pending_events"
									:style="pending_event_selected.id == facial_event.id ? 'border: 1px solid #ff2a2a !important;' : ''"
									:key="index"
									@click="pending_event_selected = facial_event">
									<div
										class="monitoring__pending_facial_events--card_title"
										:class="{'monitoring__pending_facial_events--card_selected': pending_event_selected.id == facial_event.id}"
										:title="selected_location.name">
										{{ selected_location.name }}
									</div>
									<div
										class="monitoring__pending_facial_events--card_content">
										<img
											:src="facial_event.cropped_photo || 'https://icones.pro/wp-content/uploads/2021/02/symbole-masculin-icone-l-utilisateur-gris.png'"
											alt="resized_photo">
										<div>
											Fecha:
											<br>
											<b>{{ facial_event.event_time | moment("DD MMM YYYY") }}</b>
											<br>
											Hora:
											<br>
											<b>{{ facial_event.event_time | moment("h:mm:ss a") }}</b>
										</div>
									</div>
								</v-card>
							</div>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="tab-2">
					<div
						class="monitoring__no_events"
						v-if="location_reviewed_events.length == 0">
						Aún no se han reportado eventos de dispositivos
					</div>
					<div
						class="monitoring__facial_events_section"
						v-else style="overflow-y: scroll;"
						@scroll="loadReviewedEvents">
						<div
							class="monitoring__loading_events"
							v-show="loading_events">
							<span class="material-icons">timer</span> Cargando...
						</div>
						<div class="monitoring__reviewed_facial_events">
							<v-card
								class="monitoring__reviewed_facial_events--card"
								v-for="(facial_event, index) in location_reviewed_events"
								:key=index
								@mouseenter="event_card_index = index"
								@mouseleave="event_card_index = null"
								@click="reviewed_event_selected = facial_event; show_facial_events_procedure_modal = true">
								<div
									class="monitoring__reviewed_facial_events--card_title"
									:style="facial_event.event_type.includes('UNKNOWN') ? 'background-color:#e6e6e6' : 'background-color:#e2ffdc'"
									:title="selected_location.name">
									{{ selected_location.name }}
								</div>
								<div class="monitoring__reviewed_facial_events--card_photo">
									<img
										:src="facial_event.cropped_photo || 'https://icones.pro/wp-content/uploads/2021/02/symbole-masculin-icone-l-utilisateur-gris.png'"
										alt="resized_face">
									<v-expand-transition>
										<div
											class="monitoring__reviewed_facial_events--card_info"
											:style="facial_event.event_type.includes('UNKNOWN') ? 'background-color:#e6e6e6' : 'background-color:#e2ffdc'"
											v-if="event_card_index == index">
											Fecha:
											<br>
											<b>{{ facial_event.event_time | moment("DD MMM YYYY") }}</b>
											<br>
											Hora:
											<br>
											<b>{{ facial_event.event_time | moment("h:mm:ss a") }}</b>
										</div>
									</v-expand-transition>
								</div>
							</v-card>
						</div>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</div>
		<div
			class="monitoring__event_section"
			v-if="show_event_preview">
			<div class="monitoring__event_video_container" v-if="!video_event_error">
				<event-loader
					v-if="show_event_loader"
					:eventImage="event_image"
					:fastSpeed="fast_speed_event_loader"/>

				<video-player
					v-if="show_video_player"
					:options="video_options"
					:expandedEvent="expanded_event"
					@close-full-screen="expanded_event = false"/>
			</div>
			<div class="monitoring__event_error" v-else>
				<img :src="selected_event.event_image" v-show="selected_event.event_image"/>
				<div class="monitoring__event_error--message">
					La evidencia del evento ha expirado
					<span class="material-icons">error</span>
				</div>
			</div>
			<v-card
				v-if="selected_event != null && !show_event_procedure_form"
				class="monitoring__event_section--event_container">
				<div class="monitoring__event_section--event_header">
					<div class="monitoring__event_section--event_badget">
						<span
							:style="selected_event.event_code == '131588' ? 'background-color: #ff786f'
							: selected_event.event_code == 'FUSE-ALERT-COM' ? 'background-color: #62a5ff'
							: 'background-color: #ffbe38'">
						</span>
					</div>
					<div class="monitoring__event_section--event_info">
						<p style="font-size: 24px; font-weight: 600;">{{ selected_event.device_name || 'Comunidad' }}</p>
						<div>
							<p style="margin-right: 1rem;">{{selected_event.event_time | moment("DD MMM YYYY")}}</p>
							<p>{{selected_event.event_time | moment("h:mm:ss a")}}</p>
						</div>
					</div>
					<v-btn
						v-if="selected_event.event_state == 'VERIFIED'"
						class="monitoring__event_section--verify_btn selected"
						rounded
						@click="toggleEventVerification">
						Verificado
						<span class="material-icons monitoring__event_section--verify_badget">done</span>
					</v-btn>
					<v-btn
						v-else
						class="monitoring__event_section--verify_btn non_selected"
						rounded
						@click="toggleEventVerification">
						Verificado
						<span
							class="material-icons monitoring__event_section--verify_badget"
							style="border: 1px solid #2196f3;"></span>
					</v-btn>
				</div>
				<div class="monitoring__event_section--event_actions">
					<v-btn
						rounded
						@click="removeGroupEvent">
						<v-icon
							color="warning"
							left
							style="border: 1px solid #fb8c00">mdi-arrow-left</v-icon>
						<span class="monitoring__event_section--event_actions text">Otro Evento</span>
					</v-btn>
					<v-btn
						rounded
						@click="expanded_event = true">
						<v-icon
							color="info"
							left
							style="border: 1px solid #2196f3">mdi-plus</v-icon>
						<span class="monitoring__event_section--event_actions text">Ampliar</span>
					</v-btn>
					<v-btn
						rounded
						@click="checkCameras">
						<v-icon
							color="success"
							left
							style="border: 1px solid #4caf50">mdi-play</v-icon>
						<span class="monitoring__event_section--event_actions text">Vivo</span>
					</v-btn>
				</div>
			</v-card>
			<v-card
				v-else-if="selected_event != null && show_event_procedure_form"
				class="monitoring__event_procedure">
				<div class="monitoring__event_procedure--header">
					<p style="margin-right: 1.5rem;">{{ selected_event.event_time | moment("DD MMM YYYY") }}</p>
					<p>{{ selected_event.event_time | moment("h:mm:ss a") }}</p>
					<p>Tarea realizada</p>
				</div>
				<div class="monitoring__event_procedure--form">
					<div class="monitoring__event_procedure--checkbox_group">
						<div
							v-for="(action, index) in event_actions_list"
							:key="index"
							:class="{
								'monitoring__event_procedure--checkbox' : true,
								'checked' : event_procedure.actions_taken.includes(action.text),
								'active' : selected_event.event_state !== 'REVIEWED'
							}"
							@click="toggleActionTaken(action.text)">
							<span
								class="material-icons"
								:class="'checkbox_'+action.color"
								:style="!event_procedure.actions_taken.includes(action.text) ? 'background-color: transparent' : ''">
								{{ event_procedure.actions_taken.includes(action.text) ? 'done' : '' }}
							</span>
							<p>
								{{ action.text }}
							</p>
						</div>
					</div>
					<textarea
						class="monitoring__event_procedure--textarea"
						placeholder="Comentarios..."
						:style="selected_event.event_state == 'REVIEWED' ? 'height:42%' : 'height:28%'"
						:disabled="selected_event.event_state == 'REVIEWED'"
						v-model="event_procedure.comments">
					</textarea>
					<div
						class="monitoring__event_procedure--action_buttons"
						v-if="selected_event.event_state !== 'REVIEWED'">
						<v-btn
							color="error"
							outlined
							:disabled="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '')"
							class="monitoring__event_procedure--action_button"
							@click="buildEventProcedureData">
							<v-icon
								:style="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '') ? 'border: 2px solid #c7c7c7' : 'border: 2px solid #ff5252;'"
								color="error"
								left>
								mdi-close
							</v-icon>
							Sin solucionar
						</v-btn>
						<v-btn
							:disabled="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '')"
							color="primary"
							class="monitoring__event_procedure--action_button"
							@click="event_procedure.solved = true; buildEventProcedureData()">
							<v-icon
								style="margin-right: 18px;"
								:style="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '') ? 'border: 2px solid #c7c7c7' : 'border: 2px solid #1C76D2;'"
								color="primary"
								left>
								mdi-check-bold
							</v-icon>
							Solucionado
						</v-btn>
					</div>
				</div>
			</v-card>
		</div>
		<br>
		<events-procedure-modal
			v-if="show_facial_events_procedure_modal"
			:show-modal="show_facial_events_procedure_modal"
			:event-info="reviewed_event_selected || pending_event_selected"
			@send-procedure="submitEventProcedure($event)"
			@close-modal="reviewed_event_selected = null; show_facial_events_procedure_modal = false">
		</events-procedure-modal>
		<incoming-call-modal
			v-for="(incomingCall, index) in incoming_calls"
			:key="index"
			:show-modal="incomingCall.show"
			:sublocation-id="incomingCall.sublocation_id"
			@show-video-call-modal="answerCall(index)"
			@close-modal="closeModal(index)"
			:sublocation-info="incomingCall.sublocation_info">
		</incoming-call-modal>
		<video-call-modal
			:show-modal="show_video_call_modal"
			:sublocation-id="incoming_call_sublocation_id"
			@close-video-call-modal="restoreModals"
			:incoming-call-on-call="incoming_call_on_call"
			@restore-incoming-call-on-call="restoreIncomingCallOnCall">
		</video-call-modal>
		<sos-alert-modal
			:user_data="current_sos_alert"
			:speaker="current_sos_speaker"
			v-if="getSOSAlert()">
		</sos-alert-modal>
		<speaker-section v-if="getShowSpeakers()"></speaker-section>
		<invitations-form v-if="getShowInvitationsForm()"></invitations-form>
		<open-doors v-if="getShowOpenDoors()"></open-doors>
	</article>
</template>

<script>
// import NoticeIcon from "@/components/shared/icons/NoticeIcon.vue";
// import ArrowRightIcon from "@/components/shared/icons/ArrowRightIcon.vue";
import moment from 'moment';
import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
import CircleCheckIcon from "@/components/shared/icons/CircleCheckIcon.vue";
import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
import EventsProcedureModal from "./modals/EventsProcedureModal.vue";
import IncomingCallModal from "./modals/IncomingCallModal.vue";
import VideoCallModal from "./modals/VideoCallModal.vue";
import VideoPlayer from "@/components/shared/VideoPlayer.vue";
import EventLoader from "@/components/shared/EventLoader.vue";
import SOSAlert from "@/components/shared/SOSAlert.vue";
import CheckboxEmpty from "@/components/shared/icons/CheckboxEmpty.vue";
import CheckboxDone from "@/components/shared/icons/CheckboxDone.vue";
export default {
  data(){
		return{
			tabs: null,
			monitored_locations: [],
			pending_event_groups: {},
			reviewed_event_groups: {},
			location_pending_events: [],
			location_reviewed_events: [],
			location_events_type: null,
			selected_location: null,
			hikcentral_streaming_link: '',
			pending_event_selected: null,
			reviewed_event_selected: null,
			selected_event: null,
			selected_event_group: null,
			event_index: null,
			event_card_index: null,
			facial_events_ready: false,
			show_event_procedure_form: false,
			show_facial_events_procedure_modal: false,
			img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
			total_reviewed_events_pages: 0,
			last_pending_event_record: null,
			current_reviewed_events_page: 1,
			total_pending_events_count: 0,
			loading_events: false,
			show_event_preview: false,
			video_options: {
        preload: 'auto',
        autoplay: true,
        controls: true,
        sources: []
      },
			event_actions_list: [
				{text: "Llamada a Guarda", color: "primary"},
				{text: "Llamar a Policía", color: "alert"},
        // {text: "Llamada a Supervisor", color: "primary"},
				{text: "Llamada al Cliente", color: "primary"},
				{text: "Intrusión", color: "alert"},
				{text: "CCTV Verificado", color: "primary"},
				{text: "Envío Supervisor", color: "alert"},
				{text: "Ronda Guarda", color: "primary"},
				{text: "Falsa alarma", color: "success"},
				{text: "Usuario identificado", color: "primary"},
				{text: "Falsa alarma - Mascota", color: "success"},
				{text: "Falla Energía/Internet", color: "success"},
      ],
			show_event_loader: false,
			show_video_player: false,
			fast_speed_event_loader: false,
			event_image: '',
			expanded_event: false,
			event_procedure: {
        actions_taken: [],
        comments: "",
        solved: false,
      },
			search_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			search_date_changed: false,
			show_incoming_call_modal: false,
			show_video_call_modal: false,
			incoming_call_sublocation_id: '',
			on_call: false,
			connection_id: '',
			incoming_call_on_call: false,
			location_info: [],
			incoming_calls: [],
			is_image: false,
			current_sos_alert: '',
			current_sos_speaker: false,
			today_active: false,
			pending_active: false,
			new_event_locations: [],
			video_event_error: false
		}
  },
  mounted(){
		console.log('Created Component')
		this.getUserMonitoredCompanies()
		this.connection_id = this.getUserId()
  },
  channels: {
		VirtualGuardChannel: {
      connected() {
        console.log("Connected to digital guard channel!");
      },
      received(data) {
				if(data){
					const connectionId = data.data.connection_id;
					// Verifica si el mensaje proviene de la misma conexión (emisor)
					if (connectionId !== this.connection_id) {
						console.log('------> data from digital guard', data)
						if(data.data.calling == true){
							this.incoming_call_sublocation_id = data.data.sublocation_id
							this.sublocation_info = data.data.sublocation_info
							var call = {}
							call.show = true
							call.sublocation_id = data.data.sublocation_id
							call.sublocation_info = data.data.sublocation_info
							this.incoming_calls.push(call)
							console.log('------------------>this.incoming_calls', this.incoming_calls)
							this.show_incoming_call_modal = true
							if(this.on_call == true){
								console.log('on calling ---->')
								this.onCall(data.data.sublocation_id)
							}
						}else if (data.data.notify_call_ended == true) {
							console.log("-----------> notify call ended");
							this.restoreModals();
						}else{
							const matchingIncomingCalls = this.incoming_calls.filter(incomingCall => incomingCall.sublocation_id === data.data.sublocation_id);
							console.log('matchingIncomingCalls--------->', matchingIncomingCalls);
							matchingIncomingCalls.forEach(match => {
								const index = this.incoming_calls.indexOf(match);
								if (index !== -1) {
									this.incoming_calls.splice(index, 1);
								}
							});
						}
					}
				}
      },
      disconnected() {
        console.log("Disconnected to digital guard channel!");
      }
    },
		EventsMonitoringChannel: {
			connected() {
				console.log("Connected to events monitoring channel!");
			},
			received(data) {
				console.log("llega un evento por el canal de monitor!", data);
				
				if('event_media' in data.message) this.updateEventMedia(data.message);
				else if((data.message.suspended_event != true || data.message.suspended_event === undefined) && data.message.event_code != "FUSE-ALERT-SOS"){
					this.addToCurrentEvent(data.message)
					this.arraymove(data.message.company_id, data.message.location_id)
					let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
					audio.play();
					let company_index = this.getMonitoredCompanies().findIndex(e => e.id === data.message.company_id)
					this.getMonitoredCompanies()[company_index].pending_events += 1
					let location_index = this.getMonitoredCompanies()[company_index].monitored_locations.findIndex(e => e.id === data.message.location_id)
					this.getMonitoredCompanies()[company_index].monitored_locations[location_index].pending_events += 1
					if(company_index === this.getMonitoredCompanies().findIndex(e => e.id === this.getSelectedCompany()) && location_index === this.selected_location){
						let existent_event = this.location_pending_events.filter(e => e.id === data.message.id);
						if(existent_event.length === 0){
							if(["KNOWN FACE", "UNKNOWN FACE"].includes(data.message.event_type)){
								if(data.message.event_state == "REVIEWED") this.location_reviewed_events.unshift(data.message);
								else this.location_pending_events.unshift(data.message);
								this.pending_event_selected = this.pending_event_selected == null ? this.location_pending_events[0] : this.pending_event_selected
							} else if(["131585", "131588", "131331", "FUSE-ALERT-SUSPEND", "FUSE-ALERT-ACTIVATE", "FUSE-ALERT-COM"].includes(data.message.event_code)){
								this.location_pending_events.unshift(data.message);
								this.pending_event_groups = this.groupEvents(this.location_pending_events)
							}
						}
					}
				}
				if(["FUSE-ALERT-SOS"].includes(data.message.event_code)) {
					let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
					audio.play();
					console.log("Event Code Excluded !!!!!!!!!! ", data.message.event_code);
					this.current_sos_alert = data.message.text_message
					this.current_sos_speaker = data.message.speaker
					// data.message.speaker
					this.updateSOSAlert(true)
				}
			},
			disconnected() {
				console.log("Disconnected of events mon channel!");
			},
		},
	},
  components: {
		// "notice-icon": NoticeIcon,
		// "arrow-right-icon": ArrowRightIcon,
		"circle-icon" : CircleIcon,
		"circle-check-icon" : CircleCheckIcon,
		"arrow-circle-right-icon" : ArrowCircleRightIcon,
		"events-procedure-modal": EventsProcedureModal,
		"incoming-call-modal" : IncomingCallModal,
		"video-call-modal" : VideoCallModal,
		VideoPlayer,
		EventLoader,
		"sos-alert-modal" : SOSAlert,
		"checkbox-empty" : CheckboxEmpty,
		"checkbox-done" : CheckboxDone
	},
	watch:{
		$route (to, from){
			this.checkedCompanyEvents(this.getSelectedCompany())
			// this.sortLocations()
			this.updateCurrentLocations(to, from)
			this.hikcentral_streaming_link = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).hikcentral_streaming_link
			this.total_reviewed_events_pages = 0
			this.total_pending_events_count = 0
			this.last_pending_event_record = null
			this.current_reviewed_events_page = 1
			this.show_event_preview = false
		},
	},
	methods:{
		sortLocations(){
			console.log("Sort Locations!!!!!!!!!");
			let monitored_locations = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).monitored_locations
			// this.new_event_locations = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).monitored_locations.filter((location) => location.new_event_validator == false)
			this.monitored_locations = monitored_locations.filter((location) => location.new_event_validator == true).concat(monitored_locations.filter((location) => location.new_event_validator == false).sort((a, b) => a.pending_events - b.pending_events).reverse())

		},
		removeFromCurrentEvents(location_id){
			var current_events = this.getCurrentEvents()
			var filter_events = current_events.filter(event => event.location == location_id)
			var new_events = current_events.filter(event => !filter_events.includes(event))
			this.updateCurrentEvents(new_events)
		},
		findLocationInEvents(location){
			var vm = this;
			return (vm.getCurrentEvents().filter(event => event.location == location).length > 0)
		},
		addToCurrentEvent(new_event){
			let new_temp_event = { 'company': new_event.company_id, 'location': new_event.location_id }
			let current_events = this.getCurrentEvents()
			current_events.push(new_temp_event)
			this.updateCurrentEvents(current_events)
		},
		arraymove(company_id, location_id) {
			var arr = this.getMonitoredCompanies()
			console.log("Before ", arr);
			var fromIndex = arr.findIndex(e => e.id === company_id)
			var toIndex = 0
			var element = arr[fromIndex];
			element.new_event_validator = true
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			console.log("After ", arr);
			this.updateMonitoredCompanies(arr)
			this.arrayLocationsMove(company_id, location_id)
		},
		arrayLocationsMove(company_id, location_id) {
			var company = this.getMonitoredCompanies().find(e => e.id === company_id)
			var arr = company.monitored_locations
			console.log("Before Locations", arr);
			var fromIndex = arr.findIndex(e => e.id === location_id)
			var toIndex = 0
			var element = arr[fromIndex];
			element.new_event_validator = true
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			console.log("After ", arr);

			// this.updateMonitoredCompanies(company)
		},
		checkedCompanyEvents(company_id) {
			console.log("Checked Company " + company_id);
			var arr = this.getMonitoredCompanies()
			var fromIndex = arr.findIndex(e => e.id === company_id)
			var element = arr[fromIndex];
			element.new_event_validator = false
			element.monitored_locations = element.monitored_locations.filter((location) => location.new_event_validator == true).concat(element.monitored_locations.filter((location) => location.new_event_validator == false).sort((a, b) => a.pending_events - b.pending_events).reverse())
			this.updateMonitoredCompanies(arr)
		},
		closeModal(index){
			this.incoming_calls[index].show = false
			// this.show_incoming_call_modal = false;
			this.incoming_calls.splice(index, 1)
		},
		selectDate(todayDate, all_pending_events){
			this.show_event_preview = false
			this.search_date_changed = true
			this.selected_event = null
			this.selected_event_group = null
			this.last_pending_event_record = null
			this.current_reviewed_events_page = 1
			this.$refs.menu.save(this.search_date)
			if(all_pending_events !== undefined){
				this.search_date = ''
				this.today_active = false
				this.pending_active = true
			}
			else{
				this.search_date = todayDate ? (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : this.search_date
				if (todayDate){
					this.today_active = true
					this.pending_active = false
				}else{
					this.today_active = false
					this.pending_active = true
				}
			}
			console.log('Search date!!!', this.search_date)
			console.log('selected location!!!', this.selected_location)
			if(this.tabs === 'tab-1'){
				this.getLocationPendingEvents(this.selected_location, true)
			} else {
				this.getLocationReviewedEvents(this.selected_location)
			}
		},
		selectEvent(event, eventGroup = null){
			if(
				this.selected_event &&
				this.selected_event.event_state === "PENDING" &&
				this.selected_event.id === event.id
			) return;

			this.clearView();
			this.selected_event = event;
			this.selected_event_group = eventGroup;
			this.show_event_procedure_form = eventGroup != null || event.event_state === 'REVIEWED';
			this.show_event_preview = true;
			this.show_event_loader = true;
			this.loadEventProcedureData(event);
			if(!event.expired_evidence) this.getEventMediaFiles();
			else this.video_event_error = true;
		},
		toggleEventVerification(){
			try {
				if(this.selected_event.event_state === 'REVIEWED') return;

				this.$http.post('company_monitorings/change_event_verification',{
					data: this.encrypt({event_id: this.selected_event.id}).toString()
				}).then(response => {
					this.selected_event.event_state = response.body.current_state;
					if(this.pending_event_groups[this.selected_event.group_key].length > 0 ){
						let current_group = this.pending_event_groups[this.selected_event.group_key]
						console.log("Filter Groups");
						console.log(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length);
						if(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length ){
							this.selectEvent(this.selected_event, this.selected_event.group_key)
						}
					}
				})
			} catch (e){
				console.error(e)
			}
		},
		verifyEvent(selected_event, event_group){
			this.selectEvent(selected_event); this.selected_event_group = event_group;
			try {
				if(this.selected_event.event_state === 'REVIEWED') return;

				this.$http.post('company_monitorings/change_event_verification',{
					data: this.encrypt({event_id: this.selected_event.id}).toString()
				}).then(response => {
					this.selected_event.event_state = response.body.current_state;
					console.log("Event and groups!!!!!!!!");
					console.log(this.selected_event, this.location_pending_events, this.pending_event_groups)
					console.log(this.selected_event.group_key);
					console.log(this.pending_event_groups[this.selected_event.group_key]);
					console.log(this.pending_event_groups[this.selected_event.group_key].length);
					console.log(this.pending_event_groups[this.selected_event.group_key].length > 0 );
					if(this.pending_event_groups[this.selected_event.group_key].length > 0 ){
						let current_group = this.pending_event_groups[this.selected_event.group_key]
						console.log("Filter Groups");
						console.log(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length);
						if(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length ){
							this.selectEvent(this.selected_event, this.selected_event.group_key)
						}
					}
				})
			} catch (e){
				console.error(e)
			}
		},
		groupEvents(events){
			if(events.length === 0) return {};
			const eventGroups = {};
			const sortedEvents = [...events].reverse();
			for(let i=0; i<sortedEvents.length; i++){
				let startDate = new Date(sortedEvents[i].event_time);
				let endDate = new Date(sortedEvents[i].event_time);
				endDate = new Date(endDate.setSeconds(endDate.getSeconds() + 30));
				let filteredEvents = [];
				sortedEvents.forEach(ev => {
					if(new Date(ev.event_time) >= startDate && new Date(ev.event_time) <= endDate) filteredEvents.unshift(ev);
				})
				filteredEvents.forEach(filter_event => { filter_event.group_key = startDate })
				eventGroups[startDate] = filteredEvents;
				sortedEvents.splice(0, filteredEvents.length);
				if(eventGroups[startDate].length >= 1) i--;
			}
			console.log("Event Groups!!!!!");
			console.log(eventGroups);
			return eventGroups;
		},
		sortEventGroups(eventGroups){
			const sorted = Object.keys(eventGroups)
			.sort((a,b) => new Date(a) - new Date(b))
			.reduce((accumulator, key) => {
					accumulator[key] = eventGroups[key];
					return accumulator;
				}, {});
			return sorted;
		},
		toggleActionTaken(action){
			if(this.selected_event.event_state == "REVIEWED") return
			if(this.event_procedure.actions_taken.includes(action)){
				this.event_procedure.actions_taken.splice(this.event_procedure.actions_taken.indexOf(action), 1)
			} else {
				this.event_procedure.actions_taken.push(action)
			}
		},
		loadEventProcedureData(event){
			if(event.event_state === 'REVIEWED'){
				this.event_procedure.actions_taken = event.event_comments.split("|").slice(1);
				this.event_procedure.comments = `${event.event_comments.split("|")[0]}\n\n`;
				this.event_procedure.comments += `Revisión hecha por ${event.reviewed_by_email}\n`;
				this.event_procedure.comments += `${moment(event.review_date).format("DD MMM YYYY - h:mm a")}`;
			} else if(event.event_comments){
				this.event_procedure.actions_taken = event.event_comments.split("|").slice(1);
				this.event_procedure.comments = `${event.event_comments.split("|")[0]}`;
			} else {
				this.event_procedure = {
					actions_taken: [],
					comments: "",
					solved: false,
				}
			}
		},
		buildEventProcedureData(){
			this.submitEventProcedure({
				event_ids: this.pending_event_groups[this.selected_event_group].map(ev => ev.id),
				location_id: this.selected_location,
				review_comment: `${this.event_procedure.comments}|${this.event_procedure.actions_taken.join('|')}`,
				event_solved: this.event_procedure.solved,
				user_id: this.getUserId()
			})
		},
		saveEventProcedureTemporally(){
			this.pending_event_groups[this.selected_event_group].at(-1).event_comments = `${this.event_procedure.comments}|${this.event_procedure.actions_taken.join('|')}`;
		},
		removeGroupEvent(){
			const primaryGroupEventIndex = this.pending_event_groups[this.selected_event_group].length - 1;
			const otherEventIndex = this.pending_event_groups[this.selected_event_group].findIndex(ev => ev.id == this.selected_event.id);
			if(otherEventIndex != -1 && otherEventIndex != primaryGroupEventIndex){
				this.pending_event_groups[new Date(this.selected_event.event_time)] = this.pending_event_groups[this.selected_event_group].splice(otherEventIndex, 1);
				this.pending_event_groups = this.sortEventGroups(this.pending_event_groups);
			} else if(otherEventIndex == primaryGroupEventIndex){
				const newGroup = this.pending_event_groups[new Date(this.selected_event.event_time)].splice(0, primaryGroupEventIndex);
				this.pending_event_groups[new Date(newGroup.at(-1).event_time)] = newGroup;
				this.pending_event_groups = this.sortEventGroups(this.pending_event_groups);
			}
		},
		getEventMediaFiles(){
      try {
        this.$http.post('hikcentral/get_event_url',{event_id: this.selected_event.id})
				.then(function(response){
					this.show_event_loader = true;
					const eventURL = response.body.url;

					if(eventURL.split(".").at(-1) === 'mp4') {
						this.setUpEventVideo(eventURL);
					
					} else if(["jpg", "png", "jpeg"].includes(eventURL.split(".").at(-1))) {
						this.downloadEventVideo();
						this.event_image = eventURL;
					
					// Retry to get event frames after one minute
					} else if(!eventURL && (new Date().getTime() - new Date(this.selected_event.event_time).getTime()) >= 60000) {
						this.downloadEventVideo();
					}
        })
      } catch (e) {
        console.log(e)
      }
    },
		downloadEventVideo(){
			this.$http.post('hikcentral/event_video', {
				data: this.encrypt({eventId: this.selected_event.id}).toString()
			})
			.catch((e) => {
				this.show_event_loader = false;
				this.fast_speed_event_loader = false;
				if(e.status === 404){
					this.selected_event.expired_evidence = true;
					this.video_event_error = true;
				}
			});
		},
		answerCall(index) {
			console.log('--> answer calls')
			// this.show_incoming_call_modal = false;
			this.incoming_calls[index].show = false
			this.show_video_call_modal = true;
			if(this.on_call == true){
				this.incoming_call_on_call = true
			}
			this.on_call = true
		},
		restoreModals(){
			this.show_video_call_modal = false;
			this.on_call = false
			this.incoming_call_on_call = false
		},
		restoreIncomingCallOnCall(){
			this.incoming_call_on_call = false
		},
		onCall(sublocation_id){
			console.log('notifies you that you are on a call ---->')
			const room = `room_virtual_guard_${sublocation_id}`;
			this.$cable.perform({
			channel: "VirtualGuardChannel",
			action: "send_message",
				data: {
					room: room,
					message: 'onCall',
					on_call: true,
					sublocation_id: sublocation_id,
					connection_id: this.connection_id,
				},
			});
		},
		resize(width, height, left, top) {
			this.$refs.cropper.setCoordinates({
				width: width,
				height: height,
				left: left,
				top: top
			})
		},
		change_cropper({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		},
		getLocationPendingEvents(location_id, max_limit){
			console.log('Search date in pending events:', this.search_date)
			try {
				this.$http.get('company_monitorings/get_location_pending_events',{
					params:{
						user_id: this.getUserId(),
						company_id: this.getSelectedCompany(),
						location_id: location_id,
						last_record_id: this.last_pending_event_record,
						max_limit: max_limit,
						search_date: this.search_date
					},
					headers: {
						"X-Device-ID" : this.getDeviceId(),
						// "Authorization" : 'Bearer ' + this.getUserToken()
					},
				}).then(function(response){
					if(response.body !== ""){
						this.location_events_type = response.body.events_type
						if(this.search_date_changed){
							this.location_pending_events = response.body.pending_events
						} else {
							response.body.pending_events.forEach((element) => this.location_pending_events.push(element));
						}
						this.location_pending_events = this.clearRepeatedEvents([...this.location_pending_events]);
						this.pending_event_groups = this.groupEvents(this.location_pending_events)
						this.last_pending_event_record = response.body.last_record_id
						this.total_pending_events_count = response.body.total_pending_events_count
						this.search_date_changed = false
						if(this.location_events_type == "facial"){
							this.total_pending_events_pages = response.body.total_pages
							this.facial_events_ready = true
							if(this.pending_event_selected == null || this.location_pending_events.filter(ev => ev.id == this.pending_event_selected.id)[0] == undefined){
								this.pending_event_selected = this.location_pending_events[0]
							}
						}

						// Selecting most recent event while selecting location
						if(!this.selected_event){
							const auto_selected_group = Object.keys(this.pending_event_groups).reverse()[0];
							this.selectEvent(this.pending_event_groups[auto_selected_group][0]);
						}
					}else{
						this.location_pending_events = []
					}
					// this.pages = response.body.meta.pages
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				}, function(response){
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				})
			} catch (e) {
				alert(e.message)
			}
		},
		subscribeVirtualGuardChannel(){
			this.unsubscribeVirtualGuardChannel()
			console.log('subscribeVirtualGuardChannel')
			var companies = this.getMonitoredCompanies()
			companies.forEach(company => {
				console.log('-----------> company', company)
				if(company.monitoring_calls){
					company.monitored_locations.forEach(location => {
						location.sublocations.forEach(sublocation => {
							if(sublocation.enable_calls){
								console.log('Se suscribe al canal para la sublocación', sublocation.id)
								this.$cable.subscribe({
									channel: "VirtualGuardChannel",
									room: `room_virtual_guard_${sublocation.id}`,
									sublocation_id: sublocation.id,
								});
							}
						});
					});
				}
			});
		},
		unsubscribeVirtualGuardChannel() {
			this.$cable.unsubscribe("VirtualGuardChannel")
		},
		getUserMonitoredCompanies(){
			try {
				this.$http.get('company_monitorings/get_user_monitored_companies',{
					params:{
						user_id: this.getUserId(),
					},
					headers: {
						"X-Device-ID" : this.getDeviceId(),
						// "Authorization" : 'Bearer ' + this.getUserToken()
					},
				})
				.then(function(response){
					this.updateSelectedCompany(null)
					let companies = []
					console.log('------------------> getUserMonitoredCompanies', response)
					response.body.forEach(data => {
						let filter_locations = []
						let locations = data.monitored_locations.sort((a, b) => a.pending_events - b.pending_events).reverse()
						locations.forEach(loc => {
							filter_locations.push({
								id: loc.id,
								name: loc.name,
								pending_events: loc.pending_events,
								sublocations: loc.sublocations,
								new_event_validator: false,
								speaker: loc.speaker
							})
						})
						companies.push({
							id: data.company.id,
							icon: 'apartment',
							name: data.company.name,
							route: 'monitoring',
							hikcentral_streaming_link: data.company.hikcentral_streaming_link,
							monitored_locations: filter_locations,
							pending_events: data.company.total_pending_events,
							monitoring_calls: data.company.monitoring_calls,
							dynamic_invitations: data.company.dynamic_invitations,
							remote_open_door: data.company.remote_open_door,
							remote_comunications: data.company.remote_comunications,
							perimetral_alerts: data.company.perimetral_alerts,
							new_event_validator: false
						});
					});
					let sortedCompaniesByEvents = companies.filter((company) => company.pending_events > 0).sort((a, b) => a.pending_events - b.pending_events).reverse().concat(companies.filter((company) => company.pending_events <= 0))
					this.updateMonitoredCompanies(sortedCompaniesByEvents)

					console.log("SortedCompaniesByEvents ", sortedCompaniesByEvents);
					this.subscribeVirtualGuardChannel()
					// this.pages = response.body.meta.pages
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				}, function(response){
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
					console.log(response);
				})
			} catch (e) {
				alert(e.message)
			}
		},
		getLocationReviewedEvents(location_id){
			try {
				this.$http.get('company_monitorings/get_location_events',{
					params:{
						user_id: this.getUserId(),
						company_id: this.getSelectedCompany(),
						location_id: location_id,
						page: this.current_reviewed_events_page,
						search_date: this.search_date
					},
					headers: {
						"X-Device-ID" : this.getDeviceId(),
					},
				}).then(function(response){
					if(response.body !== ""){
						this.location_events_type = response.body.events_type
						if(this.search_date_changed){
							this.location_reviewed_events = response.body.location_events
						} else {
							response.body.location_events.forEach((element) => this.location_reviewed_events.push(element));
						}
						this.location_reviewed_events = this.clearRepeatedEvents([...this.location_reviewed_events]);
						this.reviewed_event_groups = this.groupEvents(this.location_reviewed_events)
						this.total_reviewed_events_pages = response.body.total_pages
						this.search_date_changed = false
					}else{
						this.location_reviewed_events = []
					}
					// this.pages = response.body.meta.pages
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				}, function(response){
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				})
			} catch (e) {
				alert(e.message)
			}
		},
		updateCurrentLocations(to, from){
			if (from.params.id !== undefined){
				let index = this.getMonitoredCompanies().findIndex(e => e.id === from.params.id)
				this.getMonitoredCompanies()[index].monitored_locations.forEach((element) => element.event_queue = []);
			}
			this.location_pending_events = []
			this.location_reviewed_events = []
			this.monitored_locations = []
			this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).monitored_locations.forEach(data => {
				this.monitored_locations.push({
					id: data.id,
					name: data.name,
					event_queue: [],
					pending_events: data.pending_events !== undefined ? data.pending_events : 0,
					speaker: data.speaker
				})
			})
			this.selected_location = null
			this.monitored_locations = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).monitored_locations
		},
		selectLocation(location_id){
			this.selected_location = location_id
			this.clearEventsData()
			this.today_active = true
			this.pending_active = false
			this.search_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			this.getLocationPendingEvents(location_id, true)
			this.getLocationReviewedEvents(location_id)
			this.removeFromCurrentEvents(location_id)
		},
		checkCameras(){
			// let default_url = "https://181.48.103.61/"
			// window.open(default_url, "_blank");
			window.open(this.hikcentral_streaming_link, "_blank");
		},
		resizeFacialEventPhoto(imgURL, imgParams){
			console.log(imgURL, imgParams);
		// 	console.log("<<<<<<<<<<<<<<<<<<<<< Img Params");
		// 	console.log(imgParams);
		// 	const info = { url: imgURL, crossOrigin: "*" }
		// 	try {
		// 		let $canvas = document.createElement('canvas')
		// 		let img = new Image()
		// 		img.src = imgURL
		// 		console.log("<<<<<<<<<<<<<<<<< Image");
		// 		console.log($canvas)
		// 		console.log(img);
		// 		img.crossOrigin = info.crossOrigin;
		// 		img.onload = () => {
		// 			$canvas.width = imgParams.width * img.width
		// 			$canvas.height = imgParams.height * img.height
		// 			$canvas.getContext('2d').drawImage(img, imgParams.x * img.width, imgParams.y * img.height, imgParams.width * img.width, imgParams.height * img.height, 0, 0, imgParams.width * img.width, imgParams.height * img.height)
		// 			return $canvas.toDataURL("image/jpeg")
		// 		};
		// 	} catch (e){
		// 		console.error("Algo ocurrió con el recorte de la imagen")
		// 		// res("https://cdn-icons-png.flaticon.com/512/456/456212.png")
		// 	}
		},
		submitEventProcedure(payload){
			this.show_facial_events_procedure_modal = false
			try {
				this.$http.post('company_monitorings/update_reviewed_event',{
					data: this.encrypt(payload).toString()
				})
				.then(response => {
					if(response.status == 200){
						// this.search_date = ''
						// this.search_date_changed = false
						this.show_event_preview = false
						this.location_reviewed_events = []
						this.current_reviewed_events_page = 1
						// this.last_pending_event_record = null
						// this.$refs.eventList.scrollTo(0,0)
						if(payload.event_solved){
							if(this.selected_event != null && this.selected_event_group != null){
								const groups_ids = this.pending_event_groups[this.selected_event_group].map(ev => ev.id)
								this.location_pending_events = this.location_pending_events.filter(ev => !groups_ids.includes(ev.id))
								this.pending_event_groups = this.groupEvents(this.location_pending_events)
								this.search_date_changed = true
								this.last_pending_event_record = null
								this.getLocationPendingEvents(this.selected_location, true)
							} else {
								let updated_event_index = this.location_pending_events.findIndex(e => e.id === payload.event_ids[0]);
								this.location_pending_events.splice(updated_event_index, 1)
								this.getLocationPendingEvents(this.selected_location, false)
							}
						} else {
							this.saveEventProcedureTemporally();
						}
						this.getLocationReviewedEvents(this.selected_location)
						this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).pending_events -= 1
						this.monitored_locations[this.selected_location].pending_events -= 1
						this.selected_event = null
						this.selected_event_group = null
						if(this.pending_event_selected == null || this.location_pending_events.filter(ev => ev.id == this.pending_event_selected.id)[0] == undefined){
							this.pending_event_selected = this.location_pending_events[0]
						}
					} else {
						console.error(response.body.ans)
					}
				})
			} catch (e) {
				console.log(e)
			}
		},
		loadPendingEvents(e){
			if(this.loading_events) return
			let { scrollTop, clientHeight, scrollHeight } = e.target;
			if (scrollTop + clientHeight >= scrollHeight - 2) {
				if(this.location_pending_events.length < this.total_pending_events_count){
					this.loading_events = true;
					setTimeout(() => {
						this.getLocationPendingEvents(this.selected_location, true)
						this.loading_events = false;
					}, 700);
				}
			}
		},
		loadReviewedEvents(e){
			if(this.loading_events) return
			let { scrollTop, clientHeight, scrollHeight } = e.target;
			if (scrollTop + clientHeight >= scrollHeight - 2) {
				if(this.current_reviewed_events_page < this.total_reviewed_events_pages){
					console.log('Loading more reviewed events...')
					this.loading_events = true;
					setTimeout(() => {
						this.current_reviewed_events_page++;
						this.getLocationReviewedEvents(this.selected_location)
						this.loading_events = false;
					}, 700);
				}
			}
		},
		clearView(){
			this.show_event_loader = false;
			this.fast_speed_event_loader = false;
			this.event_image = '';
			this.show_video_player = false;
			this.video_event_error = false;
		},
		clearEventsData(){
			this.location_pending_events = []
			this.location_reviewed_events = []
			this.pending_event_groups = {}
			this.reviewed_event_groups = {}
			this.total_reviewed_events_pages = 0
			this.total_pending_events_count = 0
			this.last_pending_event_record = null
			this.current_reviewed_events_page = 1
			this.show_event_preview = false
			this.selected_event = null
			this.selected_event_group = null
			this.tabs = null
			this.search_date = ''
			this.search_date_changed = false
		},
		updateEventMedia(data){
			if(data.event_id === this.selected_event.id) {
				if(data.event_evidence_expired){
					this.selected_event.expired_evidence = true;
					this.video_event_error = true;
					return
				}
				const eventMediaUrl = data.event_media;
				const eventMediaExtension = eventMediaUrl.split(".").at(-1);

				if(eventMediaExtension === 'mp4') this.setUpEventVideo(eventMediaUrl);
				else if(["jpg", "png", "jpeg"].includes(eventMediaExtension)) {
					this.downloadEventVideo();
					this.event_image = eventMediaUrl;
				}
			}
		},
		setUpEventVideo(videoUrl){
			this.fast_speed_event_loader = true;
			this.selected_event.event_image = videoUrl;
			this.video_options.sources = [];
			this.video_options.sources.push({src: this.selected_event.event_image, type: 'video/mp4'});
			this.show_video_player = true
			setTimeout(() => {
				this.show_event_loader = false;
				this.event_image = '';
			}, 600);
		},
		clearRepeatedEvents(eventList){
			return eventList.reduce((accumulator, currentValue) => {
				if (accumulator.some(item => item.event_time === currentValue.event_time && item.event_code === currentValue.event_code)) {
					currentValue.hide = true
				}
				accumulator.push(currentValue);
				return accumulator;
			}, []);
		}
	}
}
</script>

<style scoped>
.cropper {
	height: 85px;
	width: 85px;
	background: #DDD;
}
</style>

@@ -0,0 +1,176 @@
<template>
  <article class="comment_event">
    <section class="comment_event__img_container"></section>
    <section class="comment_event__form_container">
      <div
        class="comment_event__close comment_event__close"
        @click="updateShowCommentEventModal(false)"
      >
        <close-icon></close-icon>
      </div>
      <h4><b>Notas</b></h4>
      <div>
        <div class="comment_event__form_input">
          <label for="company_id"><b>Compañía</b> <span>*</span></label>
          <v-select
            id="company_id"
            filled
            name="company_id"
            :items="monitoredCompanies"
            item-text="name"
            item-value="id"
            label="Seleccionar Compañía"
            v-validate="'required'"
            v-model="comment_event.company_id"
          ></v-select>
          <p
            v-show="errors.has('company_id')"
            class="invitation__new--error_message"
          >
            Debes seleccionar una compañía
          </p>
        </div>

        <div class="comment_event__form_input">
          <label for="company_id"><b>Fecha Evento</b> <span>*</span></label>
          <date-picker
            v-model="comment_event.date"
            type="date"
            class="comment_event__form_input--datetime_input"
            v-validate="'required'"
            :confirm="true"
            placeholder="Selecciona fecha del evento"
            confirm-text="Seleccionar"
            format="YYYY/MM/DD"
            name="date"
          >
          </date-picker>
          <p v-show="errors.has('date')" class="invitation__new--error_message">
            Debes seleccionar una fecha para el evento
          </p>
        </div>

        <div class="comment_event__form_input">
          <label for="company_id"><b>Hora Evento</b> <span>*</span></label>
          <date-picker
            v-model="comment_event.time"
            type="time"
            class="comment_event__form_input--datetime_input"
            v-validate="'required'"
            :confirm="true"
            placeholder="Selecciona hora del evento"
            confirm-text="Seleccionar"
            format="hh:mm a"
            name="time"
          ></date-picker>
          <p v-show="errors.has('time')" class="invitation__new--error_message">
            Debes seleccionar una hora para el evento
          </p>
        </div>

        <div class="comment_event__form_input">
          <label for="message"><b>Nota</b> <span>*</span></label>
          <v-textarea
            filled
            name="message"
            v-validate="'required'"
            v-model="comment_event.event_message"
          ></v-textarea>
          <p
            v-show="errors.has('message')"
            class="invitation__new--error_message"
          >
            El comentario no puede estar vacío.
          </p>
        </div>

        <div class="comment_event__buttons">
          <v-btn
            class="comment_event__buttons--send"
            @click="createCommentEvent()"
            >Crear <arrow-right-icon color="#fff"></arrow-right-icon
          ></v-btn>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import CloseIcon from "@/components/shared/icons/CloseIcon.vue";
import ArrowRightIcon from "@/components/shared/icons/ArrowRightIcon.vue";

import moment from "moment";

export default {
  data() {
    return {
      comment_event: {
        event_message: "",
        date: "",
        time: "",
        date_time: "",
        company_id: null,
        monitor_id: null,
      },
    };
  },
  components: {
    "close-icon": CloseIcon,
    "arrow-right-icon": ArrowRightIcon,
  },
  mounted() {
    this.comment_event.date = moment().toDate();
    this.comment_event.time = moment().toDate();
  },
  watch: {
    "comment_event.date"() {
      if (this.comment_event.date != null && this.comment_event.time != null) {
        this.comment_event.date_time = moment(
          moment(this.comment_event.date).format("YYYY-MM-DD") +
            " " +
            moment(this.comment_event.time).format("hh:mm:ss a")
        ).toDate();
      }
    },
    "comment_event.time"() {
      if (this.comment_event.date != null && this.comment_event.time != null) {
        this.comment_event.date_time = moment(
          moment(this.comment_event.date).format("YYYY-MM-DD") +
            " " +
            moment(this.comment_event.time).format("hh:mm:ss a")
        ).toDate();
      }
    },
  },
  computed: {
    monitoredCompanies() {
      return this.getMonitoredCompanies();
    },
  },
  methods: {
    createCommentEvent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          try {
            this.updateLoader(true);
            this.comment_event.monitor_id = this.getUserId();
            var data = this.encrypt(this.comment_event).toString();
            console.log("Data send:", this.comment_event);
            this.$http
              .post(
                "company_monitorings/save_comment_event",
                { data: data },
                {
                  headers: {
                    "X-Device-ID": this.buildDeviceId(),
                    Authorization: "Bearer " + this.getUserToken(),
                  },
                }
              )
              .then(
                function(response) {
                  if (
                    response.body.meta != undefined &&
                    response.body.meta != null
                  ) {
                    if (
                      response.body.meta.authentication_token != undefined &&
                      response.body.meta.authentication_token != null
                    ) {
                      this.checkToken(response.body.meta.authentication_token);
                    }
                  }
                  this.updateLoader(false);
                  this.updateAlertMessage(response.body.ans);
                  this.updateIsInAlert(true);
                  this.updateShowCommentEventModal(false);
                },
                function(response) {
                  if (
                    response.body.meta != undefined &&
                    response.body.meta != null
                  ) {
                    if (
                      response.body.meta.authentication_token != undefined &&
                      response.body.meta.authentication_token != null
                    ) {
                      this.checkToken(response.body.meta.authentication_token);
                    }
                  }
                  this.updateAlertMessage(
                    "Ocurrió un problema al guardar el evento"
                  );
                  this.updateIsInAlert(true);
                  this.updateLoader(false);
                }
              );
          } catch (e) {
            this.updateLoader(false);
            alert(e.message);
          }
        }
      });
    },
  },
};
</script>

<style lang="css">
.mx-datepicker {
  width: 100% !important;
  margin-top: 1% !important;
  margin-bottom: 4% !important;
  background-color: #f0f0f0 !important;
}
.mx-input {
  border: none !important;
  width: 100% !important;
  background-color: #f0f0f0 !important;
}
</style>
